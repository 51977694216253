import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const version = __APP_VERSION__;

Sentry.init({
  dsn: 'https://890345783b8649c8a901643f77ebd214@o4505513579773952.ingest.sentry.io/4505513581871104',
  release: `${version}-prod`,
  integrations: [new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !dev
});

// eslint-disable-next-line @typescript-eslint/require-await
const errorHandler = (async ({ error, event }) => {
  console.error('An error occurred on the client side:', error, event);
}) satisfies HandleClientError;

export const handleError = Sentry.handleErrorWithSentry(errorHandler);
