import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [3,2];

export const dictionary = {
		"/(app)": [~26,[3]],
		"/(admin)/admin": [4,[2]],
		"/(admin)/admin/orders": [~5,[2]],
		"/(admin)/admin/orders/[id]": [~6,[2]],
		"/(admin)/admin/products": [~7,[2]],
		"/(admin)/admin/products/new": [~9,[2]],
		"/(admin)/admin/products/upload": [10,[2]],
		"/(admin)/admin/products/[id]/edit": [~8,[2]],
		"/(admin)/admin/promo": [~11,[2]],
		"/(admin)/admin/promo/new": [~13,[2]],
		"/(admin)/admin/promo/[id]/edit": [~12,[2]],
		"/(admin)/admin/refs": [~14,[2]],
		"/(admin)/admin/refs/new": [~16,[2]],
		"/(admin)/admin/refs/[id]/edit": [~15,[2]],
		"/(admin)/admin/reviews": [~17,[2]],
		"/(admin)/admin/reviews/[type]": [~18,[2]],
		"/(admin)/admin/reviews/[type]/new": [~20,[2]],
		"/(admin)/admin/reviews/[type]/[id]/edit": [~19,[2]],
		"/(admin)/admin/stats": [21,[2]],
		"/(admin)/admin/users": [~22,[2]],
		"/(admin)/admin/users/[id]": [~23,[2]],
		"/(admin)/admin/users/[id]/actions": [~24,[2]],
		"/(admin)/admin/users/[id]/info": [~25,[2]],
		"/(app)/auth": [27,[3]],
		"/(app)/dashboard": [~28,[3]],
		"/(app)/dashboard/orders": [~29,[3]],
		"/(app)/info": [30,[3]],
		"/(app)/offer": [31,[3]],
		"/(app)/order/[id]": [~32,[3]],
		"/(app)/privacy": [33,[3]],
		"/(app)/products/[category]": [~35,[3]],
		"/(app)/product/[id]": [~34,[3]],
		"/(app)/reviews": [~36,[3]],
		"/wait": [37],
		"/work": [38]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';